import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading } from 'components/Headings/Heading';
import { heroV4 } from './HeroV4.module.css';

const heroImg = {
	1: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/1-book-emergency-dentist-near-you.png'
			alt='Book an appointment with a 24-hour emergency dentist nearby'
			width={800}
			height={559}
		/>
	),
	2: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/2-repair-broken-tooth.jpg'
			alt='What are your options to fix a cracked or chipped tooth?'
			width={800}
			height={559}
		/>
	),
	3: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/3-severe-toothache.jpg'
			alt='Treatment options for a throbbing tooth pain'
			width={800}
			height={559}
		/>
	),
	4: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/4-fix-cracked-teeth.jpg'
			alt='Tooth replacement after dental trauma'
			width={800}
			height={559}
		/>
	),
	5: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/5-knocked-out-tooth.jpg'
			alt='Avulsed tooth from an accident'
			width={800}
			height={559}
		/>
	),
	6: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/6-tooth-infection-xray.jpg'
			alt='Dental x-ray showing dental abscess'
			width={800}
			height={559}
		/>
	),
	7: (
		<StaticImage
			src='../../../../assets/images/emergency-dentist/7-get-urgent-dental-care-near-you.png'
			alt='Book a same-day appointment with your local emergency dentist'
			width={800}
			height={559}
		/>
	),
};

export const HeroV4 = ({ title, description, imgNumber }) => {
	const config = {
		id: '',
		type: 'h2',
		align_desktop: 'center',
		align_mobile: 'center',
		title,
	};

	return (
		<div className={heroV4}>
			{heroImg[imgNumber]}
			<Heading blok={config} classes='mt-2 mb-1 mb-1--mobile mt-1--mobile' />
			<p className='txt-center c-black mt-1 mb-2 mb-1--mobile mt-1--mobile'>{description}</p>
		</div>
	);
};

import React, { useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useMetaDataQuery } from 'hooks';
import { NavigationContext } from 'context/navigationContext';
import { Layout, Seo } from 'containers';
import { Cta } from 'components/Cta/Cta';
import { Faq } from 'components/Faq/Faq';
import { Features } from 'components/Features/Features';
import { HeroV1 } from 'components/Hero/HeroV1/HeroV1';
import { Logotypes } from 'components/Logotypes/Logotypes';
import { Testimonials } from 'components/Testimonials/Testimonials';
import { featuresConfig } from 'features/emergency-dentist/constants/featuresConfig';
import { heroV1config } from 'features/emergency-dentist/constants/heroV1config';
import { HeroV4 } from 'features/emergency-dentist/components/HeroV4/HeroV4';
import { faqSchema } from 'utilities';

const EmergencyDentist = ({ pageContext }) => {
    const { siteUrl } = useMetaDataQuery();
    const { pathname } = useLocation();
    const mainNavigation = pageContext.mainNavigation.items;
    const { setNavItems } = useContext(NavigationContext);
    const liveUrl = `${siteUrl}${pathname}`;
    const {
        meta_title,
        meta_description,
        hero_1_title,
        hero_1_description,
        features_title_1,
        features_description_1,
        features_title_2,
        features_description_2,
        features_title_3,
        features_description_3,
        faq_question_1,
        faq_answer_1,
        faq_question_2,
        faq_answer_2,
        faq_question_3,
        faq_answer_3,
        faq_question_4,
        faq_answer_4,
        faq_question_5,
        faq_answer_5,
        hero_4_title,
        hero_4_description,
        second_hero_4_title,
        second_hero_4_description,
        third_hero_4_title,
        third_hero_4_description,
        fourth_hero_4_title,
        fourth_hero_4_description,
        fifth_hero_4_title,
        fifth_hero_4_description,
        sixth_hero_4_title,
        sixth_hero_4_description,
        seventh_hero_4_title,
        seventh_hero_4_description,
        testimonial_author,
        testimonial_text,
        cta_title,
        cta_text,
        cta_button_text,
        cta_button_url,
    } = pageContext.data.node;

    const schema = {
        url: liveUrl,
        image: `${siteUrl}/img/dental-implants.jpg`,
        headline: hero_1_title,
        title: meta_title,
        description: meta_description,
        published: '',
        modified: '',
    };

    const faqItems = [
        { question: faq_question_1, answer: faq_answer_1, isJSONsource: true },
        { question: faq_question_2, answer: faq_answer_2, isJSONsource: true },
        { question: faq_question_3, answer: faq_answer_3, isJSONsource: true },
        { question: faq_question_4, answer: faq_answer_4, isJSONsource: true },
        { question: faq_question_5, answer: faq_answer_5, isJSONsource: true },
    ];

    const heroV4Items = [
        { title: hero_4_title, description: hero_4_description, imgNumber: 1 },
        {
            title: second_hero_4_title,
            description: second_hero_4_description,
            imgNumber: 2,
            isJSONsource: true,
        },
        {
            title: third_hero_4_title,
            description: third_hero_4_description,
            imgNumber: 3,
            isJSONsource: true,
        },
        {
            title: fourth_hero_4_title,
            description: fourth_hero_4_description,
            imgNumber: 4,
            isJSONsource: true,
        },
        {
            title: fifth_hero_4_title,
            description: fifth_hero_4_description,
            imgNumber: 5,
            isJSONsource: true,
        },
        {
            title: sixth_hero_4_title,
            description: sixth_hero_4_description,
            imgNumber: 6,
            isJSONsource: true,
        },
        {
            title: seventh_hero_4_title,
            description: seventh_hero_4_description,
            imgNumber: 7,
            isJSONsource: true,
        },
    ];

    const testimonials = { testimonial_author, testimonial_text };

    const cta = {
        button_text: cta_button_text,
        button_url: cta_button_url,
        text: cta_text,
        title: cta_title,
    };

    useEffect(() => {
        setNavItems(mainNavigation);
    }, []);

    return (
        <Layout type="page">
            <HeroV1
                {...heroV1config({
                    hero_1_title,
                    hero_1_description,
                    button_text: '1-888-577-0907',
                    button_url: 'tel:18885770907',
                })}
            />
            <Logotypes />
            <Features
                {...featuresConfig({
                    features_title_1,
                    features_title_2,
                    features_title_3,
                    features_description_1,
                    features_description_2,
                    features_description_3,
                })}
            />
            <Testimonials blok={testimonials} />
            {heroV4Items.map((item, idx) => (
                <HeroV4 key={idx} {...item} />
            ))}
            <Faq blok={{ item: faqItems, questionAsH3: true }} />
            <Cta blok={cta} />
            <Seo
                title={meta_title}
                description={meta_description}
                canonical={liveUrl}
                isLocalPage
                schema={schema}
                faq={faqSchema(faqItems)}
            />
        </Layout>
    );
};

export default EmergencyDentist;

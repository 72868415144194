import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const margins = {
	margin_top_mobile: 32,
	margin_bottom_mobile: 32,
	margin_top_desktop: 64,
	margin_bottom_desktop: 64,
};

export const paddings = {
	padding_bottom_desktop: 80,
	padding_bottom_mobile: 32,
	padding_left_desktop: 16,
	padding_left_mobile: 16,
	padding_right_desktop: 16,
	padding_right_mobile: 16,
	padding_top_desktop: 64,
	padding_top_mobile: 32,
};

const featureCol = {
	padding_bottom_desktop: 16,
	padding_bottom_mobile: 16,
	padding_left_desktop: 16,
	padding_left_mobile: 16,
	padding_right_desktop: 16,
	padding_right_mobile: 16,
	padding_top_desktop: 32,
	padding_top_mobile: 32,
};

export const column1 = {
	...featureCol,
	heading_title: 'Call Authority Dental helpline',
	image: (
		<StaticImage
			src='../../../assets/images/dentists-common/features-call.png'
			alt='Call'
			width={100}
			height={100}
		/>
	),
};

export const column2 = {
	...featureCol,
	heading_title: 'Connect with an emergency dentist',
	image: (
		<StaticImage
			src='../../../assets/images/dentists-common/features-dentist.png'
			alt='Connect'
			width={100}
			height={100}
		/>
	),
};

export const column3 = {
	...featureCol,
	heading_title: 'Book your same-day appointment',
	image: (
		<StaticImage
			src='../../../assets/images/dentists-common/features-patients.png'
			alt='Book'
			width={100}
			height={100}
		/>
	),
};

export const featuresConfig = ({
	features_title_1,
	features_title_2,
	features_title_3,
	features_description_1,
	features_description_2,
	features_description_3,
}) => ({
	...margins,
	...paddings,
	columns: [
		{ ...column1, heading_title: features_title_1, text: features_description_1 },
		{ ...column2, heading_title: features_title_2, text: features_description_2 },
		{ ...column3, heading_title: features_title_3, text: features_description_3 },
	],
});
